/*!
 * Start Bootstrap - Resume v5.0.8 (https://startbootstrap.com/template-overviews/resume)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-resume/blob/master/LICENSE)
 */
body {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 54px;
  color: #868e96;
}

@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: 17rem;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Saira Extra Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #343a40 !important;
}

h1 {
  font-size: 6rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 2rem;
}

p.lead {
  font-size: 1.15rem;
  font-weight: 400;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Saira Extra Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
}

.social-icons a {
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
  background-color: #495057;
  color: #fff !important;
  border-radius: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3.5rem;
  margin-right: 1rem;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:hover {
  background-color: #BD5D38;
}

.dev-icons {
  font-size: 3rem;
}

.dev-icons .list-inline-item i:hover {
  color: #BD5D38;
}

#sideNav .navbar-nav .nav-item .nav-link {
  font-weight: 800;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}

#sideNav .navbar-toggler:focus {
  outline-color: #d48a6e;
}

@media (min-width: 992px) {
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100vh;
  }
  #sideNav .navbar-brand {
    display: flex;
    margin: auto auto 0;
    padding: 0.5rem;
  }
  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    max-height: 10rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }
  #sideNav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }
  #sideNav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }
  #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }
}
section.resume-section {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  max-width: 75rem;
}

section.resume-section .resume-item .resume-date {
  min-width: none;
}

@media (min-width: 768px) {
  section.resume-section {
    min-height: 100vh;
  }
  section.resume-section .resume-item .resume-date {
    min-width: 18rem;
  }
}
@media (min-width: 992px) {
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
.bg-primary {
  background-color: #BD5D38 !important;
}

.text-primary {
  color: #BD5D38 !important;
}

a {
  color: #BD5D38;
}

a:hover, a:focus, a:active {
  color: #824027;
}

/** login **/
.login {
  background: #F7F7F7;
}

.login .fa-paw {
  font-size: 26px;
}

a.hiddenanchor {
  display: none;
}

.login_wrapper {
  right: 0px;
  margin: 0px auto;
  margin-top: 5%;
  max-width: 350px;
  position: relative;
}

.registration_form, .login_form {
  position: absolute;
  top: 0px;
  width: 100%;
}

.registration_form {
  z-index: 21;
  opacity: 0;
  width: 100%;
}

.login_form {
  z-index: 22;
}

#signup:target ~ .login_wrapper .registration_form, #signin:target ~ .login_wrapper .login_form {
  z-index: 22;
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

#signup:target ~ .login_wrapper .login_form, #signin:target ~ .login_wrapper .registration_form {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -ms-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

.animate {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 0.5s;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: both;
  -o-animation-duration: 0.5s;
  -o-animation-timing-function: ease;
  -o-animation-fill-mode: both;
  -ms-animation-duration: 0.5s;
  -ms-animation-timing-function: ease;
  -ms-animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

/** /login **/
.login {
  background: #F7F7F7;
  color: #7e7e7e;
}

.divider:after,
.login_wrapper {
  right: 0;
  margin: 5% auto 0;
  max-width: 350px;
  position: relative;
}

.login_form {
  z-index: 22;
}

.login_form, .registration_form {
  position: absolute;
  top: 0;
  width: 100%;
}

.login_content {
  margin: 0 auto;
  padding: 25px 0 0;
  position: relative;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  min-width: 280px;
}

.login_content .divisores:before {
  content: "";
  display: block;
  height: 1px;
  width: 30%;
  background: #7e7e7e;
  background: linear-gradient(right, #7e7e7e 0, #fff 100%);
  position: absolute;
  /* left: 0; */
  top: 3%;
  transform: translateY(-50%);
}

.login_content .divisores:after {
  content: "";
  display: block;
  height: 1px;
  width: 30%;
  background: #7e7e7e;
  background: linear-gradient(left, #7e7e7e 0, #fff 100%);
  position: absolute;
  right: 0;
  top: 3%;
  transform: translateY(-50%);
}

.login_content form {
  margin: 20px 0;
  position: relative;
}

.login_content h1 {
  font: 400 25px Helvetica, Arial, sans-serif;
  letter-spacing: -0.05em;
  line-height: 20px;
  margin: 10px 0 30px;
}

.login_content form input[type=text], .login_content form input[type=email], .login_content form input[type=password] {
  border-radius: 3px;
  -ms-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -o-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  border: 1px solid #c8c8c8;
  color: #777;
  margin: 0 0 20px;
  width: 100%;
}

.btn {
  border-radius: 3px;
}

.btn, .buttons, .modal-footer .btn + .btn, button {
  margin-bottom: 5px;
  margin-right: 5px;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.separator {
  border-top: 1px solid #D8D8D8;
  margin-top: 10px;
  padding-top: 10px;
}

.logo {
  justify-content: center;
}

/* Datatables */
.bg-light {
  background-color: #ededed !important;
}

.bg-primary {
  background-color: #007bff !important;
}

.text-primary {
  color: #007bff !important;
}

a {
  color: #868e96;
}

a:hover, a:focus, a:active {
  color: #8eb4db;
}

.social-icons a:hover {
  background-color: #8eb4db;
}

.dev-icons {
  font-size: 3rem;
}

.dev-icons .list-inline-item i:hover {
  color: #007bff;
}

.img-val {
  width: 200px;
  height: 200px;
}

.d-ruby {
  display: ruby;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.linea {
  border-top-style: solid;
  border-top-color: lightgray;
  border-top-width: 1px;
  padding-top: 10px;
}

.img-sinval {
  width: 30%;
  height: auto;
}

/* Datatables */
.bg-light {
  background-color: #ededed;
}

input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 76px;
  height: 40px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + .switch {
  background: #bada55;
}

input:checked + .switch:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.switch:active:after {
  width: 65px;
}

.estado {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 22px;
  width: 86px;
}

.activo {
  background: green;
}

.inactivo {
  background: red;
}

.estado p {
  color: white;
  font-weight: bold;
}

.badge-style {
  font-size: 100% !important;
  font-weight: 500 !important;
}

.checkbox-wrapper-3 input[type=checkbox] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-3 .toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-3 .toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9A9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}

.checkbox-wrapper-3 .toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}

.checkbox-wrapper-3 .toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(79, 46, 220, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.checkbox-wrapper-3 #cbx-3:checked + .toggle:before {
  background: #947ADA;
}

.checkbox-wrapper-3 #cbx-3:checked + .toggle span {
  background: #4F2EDC;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}

.checkbox-wrapper-3 #cbx-3:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.checkbox-wrapper-4 input[type=checkbox] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-4 .toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9A9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}

.checkbox-wrapper-4 .toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}

.checkbox-wrapper-4 .toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(78, 46, 220, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.checkbox-wrapper-4 #cbx-4:checked + .toggle:before {
  background: #947ADA;
}

.checkbox-wrapper-4 #cbx-4:checked + .toggle span {
  background: rgb(79, 46, 220);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}

.checkbox-wrapper-4 #cbx-4:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.checkbox-wrapper-5 input[type=checkbox] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-5 .toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-5 .toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9A9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}

.checkbox-wrapper-5 .toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}

.checkbox-wrapper-5 .toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(220, 130, 46, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.checkbox-wrapper-5 #cbx-5:checked + .toggle:before {
  background: rgb(219, 155, 95);
}

.checkbox-wrapper-5 #cbx-5:checked + .toggle span {
  background: rgb(220, 130, 46);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(220, 130, 46, 0.2);
}

.checkbox-wrapper-5 #cbx-5:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.checkbox-wrapper-6 input[type=checkbox] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-6 .toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-6 .toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9A9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}

.checkbox-wrapper-6 .toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}

.checkbox-wrapper-6 .toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(220, 130, 46, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.checkbox-wrapper-6 #cbx-6:checked + .toggle:before {
  background: rgb(219, 155, 95);
}

.checkbox-wrapper-6 #cbx-6:checked + .toggle span {
  background: rgb(220, 130, 46);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(220, 130, 46, 0.2);
}

.checkbox-wrapper-6 #cbx-6:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.centrar-vertical {
  display: flex;
  align-items: center;
}

.truncar {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

.ml-10 {
  margin-left: 10px;
}